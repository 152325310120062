@import "~antd/dist/antd.less"; // Import Ant Design styles by less entry

* {
    box-sizing: border-box;
    font-family: 'Roboto', 'Arial', sans-serif;
    margin: 0;
    padding: 0;
    -webkit-font-smoothing: antialiased;
}

.layout {
    display: flex;
    justify-content: center;
    padding: 20px 20px 20px 20px;
}

.kdetosakra-logo {
    max-width: 50px;
}

.about-container h2,
.about-container h3,
.end-is-near-container h2,
.end-is-near-container h3 {
    font-weight: bold;
}

ul,
ol {
    padding-left: 40px;
}

li {
    margin: 5px 0 5px 0;
}

.result-modal-container h3,
.result-modal-container h2 {
    font-weight: bold;
}

.result-modal-container p {
    font-size: 15px;
}

ul.video-list {
    list-style-type: none;
    margin: 0;
    padding: 0;
}

.ant-layout {
    background-color: #f0f0f0;
}

.ant-layout-content {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
}

.tutorial-section {
    margin: 15px 0 30px 0;
}

.tutorial-section h4 {
    font-style: italic;
}

.ant-alert-content {
    font-size: 15px;
}

.ant-layout-footer {
    padding: 13px 50px !important;
    background: #ffffff;

    p {
        padding: 0;
        margin: 0;
    }
}

.ant-tabs {
    width: 100%;
}

.home-overview {
    display: flex;
    flex-direction: column;
    justify-content: center;

    .home-overview-hero {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-bottom: 15px;
        text-align: center;

        h1 {
            font-size: xxx-large;
            color: #c80707;
            margin-bottom: 7px;
        }

        p {
            font-size: large;
        }

        p.subtitle {
            font-size: small;
        }
    }
}

.section {
    width: 100%;
    height: 300px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.logo {
    display: flex;
    align-items: center;
    gap: 30px;

    h2 {
        line-height: 0;
        padding: 0;
        margin: 0;
        color: #c80707;
    }

    h2:hover {
        color: #000;
    }
}

/* this is flex container */
.menu-container {
    color: #828282;
    display: flex;
    justify-content: center;
    height: 75px;
    padding: 0 80px 0 80px;
}

.randomPlace__modes {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 15px;
}

.logo-not-displayed {
    display: none;
}

.menu-without-padding {
    padding: 0 25px 0 25px;
}

.menu {
    width: 90%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 18px;
}

.links {
    display: flex;
    justify-content: flex-end;
}

.main-menu {
    display: flex;
    flex: 1;
    justify-content: flex-end;
    align-items: center;
}

.main-menu--battle {
    display: flex;
    flex: 1;
    justify-content: flex-start;
    align-items: center;
    padding-left: 22px;
}

.game-start-button-group {
    display: flex;
    justify-content: center;
    align-items: center;
}

.menu-game-info {
    display: flex;
    flex: 1;
    justify-content: flex-end;
    align-items: center;
}

.menu-game-info-box {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: 60px;
    min-width: 100px;
}

.menu-game-info-box-head {
    color: rgba(0, 0, 0, 0.45);
    font-size: 12px;
}

.menu-game-info-box-item {
    color: rgba(0, 0, 0, 0.85);
    font-size: 16px;
}

.menu-item-with-icon-container {
}

.menu-item-icon {
    display: none;
}

.menu-item {
    padding: 0;
    display: inline-block;
}

.menu-separator {
    color: #e1e3e8;
    margin: 0 15px 0 15px;
}

.ant-card {
    background-color: #f0f0f0;
    width: 300px !important;
    margin: 0 1px 0 1px !important;
}

.ant-card-bordered {
    margin-bottom: 30px !important;
}

input[type='text'],
select,
number {
    width: 100%;
    padding: 12px 20px;
    margin: 0 0 10px 0;
    box-sizing: border-box;
}

.button-play {
    margin: 0 5px 0 5px;
}

label {
    padding: 12px 12px 12px 0;
    display: inline-block;
}

.game-mode-container {
    padding: 20px 0;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    height: 600px;
}

.game-mode-item {
    border: 1px solid #000;
    width: 400px;
    height: 300px;
    padding: 5px;
}

.about-container {
    padding: 0 20px 0 20px;
    width: 900px;
    justify-content: center;
}

.end-is-near-container {
    padding: 0 20px 0 20px;
    width: 900px;
    justify-content: center;
}

.heraldry-meta-card .ant-card-meta-title {
    font-weight: bold;
    color: #fff;
}

.heraldry-meta-card .ant-card-meta-description {
    color: #E5E4E4FF;
}

.customPlaceForm ul.smap-suggest {
    bottom: -70px;
}

.result-container {
    height: 200px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: flex-start;
    flex: 1;
}

.result-final-order {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 50%;
}

.result-modal-container {
    display: flex;
    flex: 1;
    justify-content: flex-start;
    flex-direction: column;
    margin: 0 30px 0 30px;
}

.result-modal-container-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    margin-bottom: 10px;
}

.result-modal-container-item {
    display: flex;
    justify-content: space-around;
    flex-direction: column;
    align-items: center;
    margin: 5px 0 10px 0;
    line-height: 2em;
}

.result-modal-container-more-info {
    display: flex;
    flex-direction: column;
}

.result-modal-container-more-info h3 {
    text-transform: uppercase;
    font-size: 1em;
    font-weight: normal;
    color: #737373;
    margin-bottom: 15px;
}

.result-modal-container-more-info-city {
    display: flex;
    flex-direction: row;
}

.result-modal-container-more-info-city-headline {
    font-size: 1.5em;
    font-weight: bold;
    margin-bottom: 0px;
}

.result-modal-container-more-info-city-left-summary {
    text-align: justify;
    color: #737373;
    font-size: 0.8em;
    margin-bottom: 10px;
}

.result-modal-container-more-info-city-right {
    display: flex;
    justify-content: center;
    width: 30%;
}

.result-modal-container-more-info-wiki {
    margin-bottom: 10px;
}

.result-modal-container-more-info-wiki a {
    text-decoration: underline;
}

.result-modal-container-more-info-wiki a:hover {
    text-decoration: none;
    color: #000;
}

.result-city-emblem {
    width: 90px;
    aspect-ratio: auto 90 / 95;
    height: 95px;
    position: relative;
    bottom: 25px;
}

.heraldry-city-emblem {
    height: 50vh;
}

.result-container-item {
    display: flex;
    justify-content: space-around;
    flex-direction: column;
    align-items: center;
    margin: 5px 0 20px 0;
    line-height: 2em;
}

.battle-info {
    position: fixed;
    z-index: 10;
}

.result-rounds-container {
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    width: 100%;
}

.result-round {
    display: flex;
    flex-direction: row;
    margin: 10px 0 10px 0;
    flex: 1;
    justify-content: center;
    align-items: center;
}

.result-round-color {
    height: 25px;
    width: 25px;
    border-radius: 50%;
    margin-right: 15px;
    display: flex;
}

.result-round-no {
    display: flex;
    flex: 1;
    justify-content: center;
}

.result-round-distance {
    display: flex;
    flex: 2;
    justify-content: center;
}

.result-round-score {
    display: flex;
    flex: 2;
    justify-content: center;
}

.result-round-other-info {
    display: flex;
    flex: 3;
    justify-content: center;
}

.result-modal-button {
    padding: 0 0 10px 0;
    text-align: center;
}

.game-mode-info-container {
    flex: 1;
    color: #fff;
    padding: 20px 0;
    display: flex;
    justify-content: center;
}

.ant-spin-nested-loading {
    width: 100% !important;
}

.game-screen-container {
    display: flex;
    width: 100%;
}

.panorama-container {
    display: flex;
    width: 100%;
    justify-content: center;
}

.battle-users-container {
    min-width: 250px;
    display: flex;
    flex-direction: column;
    border-right: 1px solid #e1e3e8;
    padding: 10px 15px 0 15px;
}

.battle-users-container p {
    font-size: 14px;
}

.battle-users-container h5 {
    margin-top: 0 !important;
    margin-bottom: 20px !important;
}

.battle-users-container--toggle {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: green;
    position: absolute;
}

.warning-msg {
    padding: 10px;
    margin-bottom: 12px;
    color: #9f6000;
    font-size: 14px;
    background-color: #feefb3;
    border-radius: 3px 3px 3px 3px;
}

.battle-players {
    display: flex;
    flex-direction: column;
    margin-top: 5px;
}

.battle-settings .ant-checkbox-wrapper {
    font-size: 14px !important;
    margin-bottom: 15px !important;
}

.battle-settings-slider-container {
    display: flex;
    flex-direction: row;
}

.battle-settings-slider {
    display: flex;
    width: 90%;
}

.battle-settings-slider-desc {
    display: flex;
    width: 10%;
}

.battle-players-detail {
    display: flex;
    flex: 1;
    flex-direction: row;
    padding-bottom: 5px;
    margin-bottom: 10px;
    border-bottom: 1px solid #e1e3e8;
}

.battle-players-detail input[type='text'] {
    padding: 0;
    margin: 0;
    font-size: 13px;
    font-weight: bold;
}

.battle-players-detail-result {
    display: flex;
    flex: 1;
    flex-direction: column;
    padding-bottom: 5px;
    margin-bottom: 10px;
    border-bottom: 1px solid #e1e3e8;
}

.battle-players-detail-player-result {
    display: flex;
    flex: 1;
    justify-content: flex-start;
}

.battle-players-detail-player-result--name {
    display: flex;
    width: 90%;
    justify-content: flex-start;
    font-size: 13px;
}

.battle-players-detail--name {
    display: flex;
    flex: 3;
    justify-content: flex-start;
    font-size: 13px;
}

.battle-link-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.battle-link-container .ant-input {
    display: flex;
    width: 90%;
}

.battle-link-container--clipboard {
    display: flex;
    width: 10%;
    justify-content: center;
    align-items: center;
}

.battle-players-detail--status {
    display: flex;
    flex: 1;
    justify-content: flex-end;
    align-items: center;
}

.battle-players-detail--status .ant-spin {
    margin-right: 6px;
}

.battle-players-detail--status .anticon-check-circle {
    margin-right: 5px;
}

.panorama {
    display: flex;
    width: 100%;
    justify-content: center;
}

.footer-container {
    padding: 20px 0;
    color: #5995da;
    display: flex;
    justify-content: center;
}

.footer {
    width: 90%;
    display: flex;
    justify-content: center;
}

.footer-item {
    display: flex;
    align-items: center;
}

.signup {
    margin-left: auto;
}

.smap {
    height: 100%;
    margin-bottom: 5px;
    background: #ccc;
}

.smap-result {
    height: 450px !important;
    margin-bottom: 5px;
    background: #ccc;
}

.smap-collapsible-max-max {
    width: 25px;
    height: 25px;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
    transform: rotateZ(180deg);
    pointer-events: auto;
    cursor: nw-resize;
}

.max-max-disabled {
    cursor: not-allowed;
}

.smap-collapsible-max {
    width: 25px;
    height: 25px;
    position: absolute;
    top: 0;
    left: 28px;
    z-index: 2;
    cursor: se-resize;
}

.smap-collapsible-min {
    width: 100px;
    height: 100px;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
    pointer-events: auto;
    cursor: nw-resize;
}

.smap-style {
    border: 1px solid #c80707;
}

.smap-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.highlighted {
    font-weight: bolder;
}

@media (min-width: 1024px) {
    .placeUploadForm {
        max-width: 600px;
        margin-bottom: 5px;
        min-width: 600px;
    }
}

/* Mobile Styles */
@media only screen and (max-width: 400px) {
    .placeUploadForm {
        width: 80vw;
        min-width: initial;
    }

    .game-mode-info-container {
        display: none;
    }

    .menu-container {
        padding: 30px 20px 15px 20px;
        height: 20px;
    }

    .smap-result,
    .smap {
        height: 450px !important;
        margin-bottom: 5px;
    }

    .smap-container {
        width: 100%;
    }

    .smap-collapsible-max-max,
    .smap-collapsible-max,
    .smap-collapsible-min {
        display: none;
    }

    .menu-game-info {
        font-size: 12px;
    }

    .menu-game-info-box {
        min-width: 80px;
    }

    .menu-game-info-box-head {
        font-size: 10px;
    }

    .menu-game-info-box-item {
        font-size: 8px;
    }

    .menu-item-with-icon-container {
        display: none;
    }

    .menu-item-icon {
        display: inline-block;
        color: rgb(97, 95, 95);
        margin-right: 10px
    }

    .menu-item {
        display: none;
    }

    .kdetosakra-logo {
        max-width: 40px;
    }

    .kdetosakra-logo-text {
        display: none;
    }

    .layout {
        padding: 15px 20px 20px 20px;
    }
}

/* Tablet Styles */
@media only screen and (min-width: 401px) and (max-width: 960px) {
    .end-is-near-container {
        padding: 0;
    }

    .placeUploadForm {
        width: 80vw;
        min-width: initial;
    }

    .game-mode-item {
        width: 50%;
    }

    .menu-container {
        padding: 30px 20px 15px 20px;
        height: 20px;
    }

    .smap-result,
    .smap {
        height: 450px !important;
        margin-bottom: 5px;
    }

    .smap-collapsible-max-max,
    .smap-collapsible-max,
    .smap-collapsible-min {
        display: none;
    }

    .battle-users-container {
        min-width: 120px;
    }

    .menu {
        align-items: center;
    }

    .menu-game-info-box-item {
        font-size: 15px;
    }

    .menu-item-with-icon-container {
        display: none;
    }

    .menu-item-icon {
        display: inline-block;
        color: rgb(97, 95, 95);
        margin-right: 10px
    }

    .menu-item {
        display: none;
    }

    battle-players-detail-player-result--name,
    .battle-players-detail--name {
        font-size: 10px;
    }

    .smap-container {
        width: 100%;
    }

    .kdetosakra-logo {
        max-width: 40px;
    }

    .kdetosakra-logo-text {
        display: none;
    }

    .layout {
        padding: 15px 20px 20px 20px;
    }

    .battle-users-container button,
    .battle-users-container p {
        font-size: 12px;
    }

    .warning-msg {
        font-size: 12px;
    }
}

/* Desktop Styles */
@media only screen and (min-width: 961px) {
    .game-mode-item {
        width: 50%;
    }

    .smap-container {
        position: fixed;
        bottom: 10px;
        right: 15px;
    }
}

/* Large Display Desktop Styles */
@media only screen and (min-width: 1281px) {
    .ant-card {
        width: 600px;
    }
}

@primary-color: #c80707;@font-size-base: 16px;@layout-body-background: #fff;@layout-footer-background: #fff;@border-radius-base: 4px;